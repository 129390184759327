<template>
  <div class="" v-if="isQuestionLoading"></div>
  <div class="" v-else>
    <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center justify-content-between">
      <div class="v16-description" style="color: #73738D;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 28px;"> Your Quiz Statistics</div>
<div class="d-flex gap align-items-center">
  <button @click="$refs.exportQuizProducts.openExportModal()" class="export-button">  <i
                      class="fa-solid mr-2 fa-file-export "
                    ></i>
                    Export </button>
  <button @click="$refs.resetQuizAnalytics.openResetModal()" class="export-button"> <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M2.63263 11.243C3.11896 12.6233 4.04073 13.8081 5.25906 14.619C6.47739 15.4299 7.92627 15.8229 9.3874 15.7388C10.8485 15.6548 12.2427 15.0981 13.36 14.1528C14.4772 13.2075 15.257 11.9247 15.5817 10.4978C15.9065 9.07081 15.7586 7.57697 15.1605 6.24132C14.5623 4.90568 13.5463 3.8006 12.2654 3.09258C10.9846 2.38455 9.5083 2.11195 8.05903 2.31584C5.61219 2.66007 3.9956 4.43357 2.25 6M2.25 6V1.5M2.25 6H6.75" stroke="#73738D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Reset Data </button>
</div>
      </div>
    </div>
    </div>
    <div class="analytics-box mt-4">
    <div class="">
      <div>
        <div class="" v-if="AnswerStats.length">
              <div
                class=""
                v-for="(quiz, index) in AnswerStats"
                :key="quiz.title + index"
              >
                <div
                  class="quiz-title"
                  v-b-toggle="'collapse' + index"
                  style="padding:16px"
                >
                 
                  <span class="px-2"> {{ quiz.title }}</span>
                  <div class="">
                    <span class="when-opened-thisd">
                     <i class="fa-solid fa-angle-up"></i>
                   </span>
                   <span class="when-closed-this">
                     <i class="fa-solid fa-angle-down"></i>
                   </span>
                  </div>
                </div>
<div class="">
  <b-collapse
                  :id="'collapse' + index"
                  visible
                  accordion="children-collapse"
                >
                <b-table
                 
                  head-class="text-center"
                  class="leadTable"
                  :stickyColumn="true"
                  hover
                  :responsive="true"
                  :fields="columns"
                  :items="quiz.analytics">
                  <template #cell(question_options)="data">
                    <ol class="m-0" style="padding-left: 15px;">
                      <li class="my-2" v-for="(option, index) in data.item.question_options" :key="index">{{option.name}}</li>
                    </ol>
                   
                  </template>
                  <template #cell(selected)="data">
                    <ol class="m-0" style="padding-left: 15px; list-style-type:none;">
                      <li  v-for="(option, index) in data.item.question_options" :key="index">
                        <div class="d-flex gap">
                          <div >
                                  <span
                                    class="mr-1"
                                   
                                    >{{ option.click_percentage }}%</span
                                  >
                                </div>
                                <div
                                  class="progress my-2 d-flex"
                                  style="flex: 0.7; height: 8px"
                                >
                                  <div
                                    class="progress-bar bg-yello-theme"
                                    :style="`width: ${option.click_percentage}%`"
                                  ></div>
                                
                              </div>
                        </div>
                      </li>
                    </ol>
                   
                  </template>
                  
                    <template #cell(time_passed)="data">
                      {{data.item.question_attempts}}
                    </template>
                    
                
                </b-table>
              
              </b-collapse>
</div>
                </div></div>
      </div>
    </div>
    <!-- <div class="col-12 w-100">
      <div class="text-left analytics-box mt-4">
        <div class="card-body" style="min-height: 200px">
         
          <div class="row my-3 AnsStatsTable">
            <div class="col-12" v-if="AnswerStats.length">
              <div
                class=""
                v-for="(quiz, index) in AnswerStats"
                :key="quiz.title + index"
              >
                <div
                  class="px-2 w-100 font-weight-bold quiz-title py-3"
                  v-b-toggle="'collapse' + index"
                >
                  <span class="when-opened-thisd">
                    <i class="fa-solid fa-angle-up"></i>
                  </span>
                  <span class="when-closed-this">
                    <i class="fa-solid fa-angle-down"></i>
                  </span>
                  <span class="px-2"> {{ quiz.title }}</span>
                </div>
                <b-collapse
                  :id="'collapse' + index"
                  visible
                  accordion="children-collapse"
                >
                  <table class="table" v-if="quiz.analytics.length">
                    <thead>
                      <tr>
                        <th scope="col">Question</th>
                      
                        <th
                          scope="col"
                          class="w-100 d-flex justify-content-between pr-5"
                        >
                          <span>Options</span>
                          <span>Selected</span>
                        </th>
                        <th scope="col">Times Passed</th>
                      </tr>
                    </thead>
                    <tbody class="">
                      <tr v-for="(stats, index) in quiz.analytics" :key="index">
                        <td scope="row" style="width: 35%" class="">
                          {{ stats.question }}
                        </td>
                        <td scope="row" class="w-50">
                          <div
                            class="d-flex align-items-start"
                            v-for="(option, index2) in stats.question_options"
                            :key="index2"
                          >
                            <p class="mb-0" style="flex: 0.7">
                              {{ index2 + 1 }}.
                              {{ option.name }}
                            </p>
                            <div
                              class="d-flex AnswerStatsProgress"
                              style="flex: 0.3"
                            >
                              
                                <div style="flex: 0.3" class="text-right">
                                  <span
                                    class="mr-1"
                                    style="font-size: 14px; font-weight: 600"
                                    >{{ option.click_percentage }}%</span
                                  >
                                </div>
                                <div
                                  class="progress my-2 d-flex"
                                  style="flex: 0.7; height: 10px"
                                >
                                  <div
                                    class="progress-bar bg-yello-theme"
                                    :style="`width: ${option.click_percentage}%`"
                                  ></div>
                                
                              </div>
                            </div>
                          </div>
                        </td>
                        <td scope="row" class="text-center">
                          {{ stats.question_attempts }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="col-12" v-else>
                    <p
                      class=""
                      style="font-weight: 400; font-size: 16px; color: #29292"
                    >
                      Question not found
                    </p>
                  </div>
                </b-collapse>
              </div>
            </div>
            <template v-else>
              <div
                class="d-flex flex-column align-items-center justify-content-center"
              >
                <LazyImage
                  :src="'/assets/img/noData.svg'"
                  alt=""
                  style="width: 15rem"
                  class="mt-5"
                />
                <div class="mt-3" style="text-align: center">
                  <div class="py-3" style="font-size: 30px">No Quiz found.</div>
                  Please add a quiz first.
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div> -->
    <a ref="downloadLink" style="display: none"></a>
    <ExportAnalytics
      :fields="['all', 'filtered']"
      :isFileDownload="isLoadingExport"
      id="exportQuizProducts"
      @export="exportProduct($event)"
      ref="exportQuizProducts"
    ></ExportAnalytics>
    <ResetAnalytics
      id="resetQuizAnalytics"
      :quizzes="quizzes"
      :isResetting="isLoadingReset"
      ref="resetQuizAnalytics"
      @reset="resetQuiz"
    ></ResetAnalytics>
  </div>
</div>
</template>

<script>
import ResetAnalytics from "./ResetAnalytics.vue";
import ExportAnalytics from "./ExportAnalytics.vue";
export default {
  props: ["quizzes", "quizIds", "filter", "isQuestionLoading", "AnswerStats"],
  data() {
    return {
      columns: [
        {
          key: "question",
          label: "Question",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },

        {
          key: "question_options",
          label: "Options",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
        {
          key: "selected",
          label: "Selected",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
          thStyle: { width: "30%" },
        },
        {
          key: "time_passed",
          label: "Times Passed",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading text-start",
        },
      ],
      isLoadingExport: false,
      isLoadingReset: false,
      isV1: false,
    };
  },
  computed: {
    loadingSkeleton() {
      return ` <div>
      <div>
          <div class="row w-100">
            <div class="col-12">
              <div class="card text-left analytics-box mt-4 w-100">
                <div class="card-body">
                  <div
                    class="bg-preload mr-4"
                    style="height: 40px; width: 100px; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                 
                  <div
                    class="bg-preload mt-3"
                    style="height: 5px; width: 100%; border-radius: 4px"
                  >
                    <div class="into-preload"></div>
                  </div>
                  <div class="mt-4">
                    <div
                      class="bg-preload"
                      style="height: 500px; width: 100%; border-radius: 4px"
                    >
                      <div class="into-preload"></div>
                    </div>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>`;
    },
  },
  methods: {
    async resetQuiz(quizIds) {
      this.isLoadingReset = true;

      // const response =
      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "post",
        resource: "analysis",
        method: this.isV1 ? "resetQuestionsOld" : `resetQuestions`,
        params: { quizIds: quizIds },
      });
      if (response && response.status) {
        this.$toasted.show("Stats has been reset.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        this.$refs.resetQuizAnalytics.closeResetModal();
        // this.getAnalyticsData(this.isV1);
        this.$emit("getAnalyticsTableData");
      } else {
        this.$toasted.show("Something went wrong.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
      this.isLoadingReset = false;
    },
    async exportProduct(category) {
      let data = {};
      if (category == "filtered") {
        let start_date = this.filter.start_date;
        let end_date = this.filter.end_date,
          quizId = this.quizId;
        if (this.selectedDateValue == "all") {
          start_date = "";
          end_date = "";
          quizId = [];
        }
        data = {
          startDate: start_date,
          endDate: end_date,
          quizId: quizId,
        };
      }
      this.isLoadingExport = true;

      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "post",
        resource: "analysis",
        method: this.isV1 ? "exportQuestionsOld" : `exportQuestions`,
        params: data,
      });
      const blob = new Blob([response], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const downloadLink = this.$refs.downloadLink;
      downloadLink.href = url;
      downloadLink.download = "AnswerAnalytics.csv";
      downloadLink.click();
      window.URL.revokeObjectURL(url);
      this.isLoadingExport = false;
      this.$refs.exportQuizProducts.closeExportModal();
    },
  },
  components: {
    ResetAnalytics,
    ExportAnalytics,
  },
  mounted() {
    // this.getAnalyticsData()
  },
};
</script>
<style>

</style>
<style scoped>
.collapsed > div> .when-opened-thisd,
:not(.collapsed)> div > .when-closed-this {
  display: none;
}
.analytics-box {
  border-radius: 8px;
border: 1px solid #EAECF0;
background: #FFF;
}

.bg-yello-theme {
  background: var(--primary-color)!important;
  border-radius:8px;
}
.export-button{
  border-radius: 6px;
border: 1px solid #E1E3E6;
background: #F6F6F8;
padding:12px;
text-align: center;
color: #73738D;
font-family: Poppins;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 19.5px */
 }
 .quiz-title{
  color: #73738D;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
display: flex;
justify-content: space-between;
 }
</style>
